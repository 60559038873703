body {
  background-color: rgb(26, 30, 32);
}
.hero_clients {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%; /* Ensure images don't exceed their container's width */
  height: auto; /* Maintain image aspect ratio */
}

/* 
2366 
1350 
*/

.client-grid-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 140px;
  @media (max-width: 600px) {
    max-width: 95%;
  }
}

.thumbnail-grid {
  padding-top: 60px;
  padding-bottom: 120px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Display 4 columns on desktop */
  gap: 32px; /* Add some spacing between thumbnails */

  align-items: center;
  justify-content: center;
}

.grid-item-client {
  overflow: hidden;
  border-top-left-radius: 36px;
  border-bottom-right-radius: 36px;

  position: relative;
  display: inline-block;
  transition: box-shadow 0.2s ease;
}

.grid-item-client img {
  padding: 20px;
  filter: grayscale(100%);
  transition: all 0.44s ease;
}

.grid-item-client img:hover {
  padding: 14px;
} 

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .thumbnail-grid {
      grid-template-columns: repeat(2, 1fr); /* Display 2 columns on mobile */
  }
}
