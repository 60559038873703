body {
  background-color: black;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.atag-no-style {
  text-decoration: none;
  color: whitesmoke;
}

.atag-no-style::after {
  color: whitesmoke;
}

.atag-no-style:hover {
  color: #f9e891;
  transition-duration: 0.5s;
}

.logo {
  mix-blend-mode: difference;
  opacity: 75%;
  width: 15%;
  height: auto;
  max-width: 50%;
}

.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-video {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

nav {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
}

.navbar-links-group {
  align-content: center;
  align-items: center;
  text-align: center;

  padding-top: 20px;
  padding-bottom: 20px;
}

nav .logo {
  width: 80px;
}

nav ul li {
  list-style: none;
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}

.navbar-links {
  letter-spacing: .15rem;
  text-transform: uppercase !important;
  text-decoration: none;
  color: whitesmoke;
  font-size: medium;
  transition: all 0.44s ease;
}

.navbar-links:hover {
  color: #fed35d;
}

.content {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.homepage-name {
  mix-blend-mode: difference;
  opacity: 95%;
  width: 20%;
  align-self: center;
  height: auto;
  @media (max-width: 600px) {
    width: 66%;
  }
}

.homepage-cta-button {
  font-size: small;
  font-family: 'Major Mono Display';
  background-color: #74747b0c;
  color: whitesmoke;
  border-radius: 8px;
  margin-top: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-style: none;
}

.back-video {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.homepage-footer {
  position: fixed;
  bottom: 10px;
  align-items: center;
  height: 20px;
  font-size: x-small;
  color: #f5f5f569;
}

@media (min-aspect-ratio: 16/9) {
  .back-video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .back-video {
    width: auto;
    height: 100%;
  }
}