body {
  background-color: rgb(26, 30, 32);
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hero_contact {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.email {
  font-family: 'Syne', sans-serif;
  text-align: center;
  padding: 10px;
  font-size: large;
  color: whitesmoke;
}

.footer {
  text-align: center;
  padding: 10px;
  padding-bottom: 40px;
  position: sticky;
  bottom: 0;
}

.social-icons {
  font-size: 24px;
  margin: 10px;
  text-decoration: none; /* Remove underline on links */
  color: rgba(245, 245, 245, 0.559);
  transition: all 0.44s ease;
}

.social-icons:hover {
  color: whitesmoke;
}