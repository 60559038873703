:root {
  --gradient-text: linear-gradient(99deg, rgba(230,255,251,1) 25%, rgba(196,210,221,1) 78%);
  --gradient-text-2: linear-gradient(99deg, rgba(255,251,230,1) 25%, rgba(232,225,169,1) 78%);
}

body {
  background-color: rgb(0, 0, 0);
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.atag-no-style {
  text-decoration: none;
  color: whitesmoke;
}

.atag-no-style::after {
  color: whitesmoke;
}

.atag-no-style:hover {
  color: #f9e891;
  transition-duration: 0.5s;
}

.logo {
  width: 15%;
  height: auto;
  max-width: 50%;
}

.hero_projects {
  /* background-size: 4px 4px;
  background-color: #1717173e;
  background-image:
    linear-gradient(to right, #2c2c2c1b 1px, transparent 1px),
    linear-gradient(to bottom, #2c2c2c1b 1px, transparent 1px); */
  width: 100%;
  height: auto;
  position: relative;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 80px;
}

/* .back-video {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
} */

nav {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
}

nav .logo {
  width: 80px;
}

nav ul li {
  list-style: none;
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}

.navbar-links {
  text-transform: uppercase;
  text-decoration: none;
  color: whitesmoke;
  font-size: medium;
}

.content {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.homepage-name {
  font-family: 'Major Mono Display';
  color: #fffbe1;
  letter-spacing: 10px;
}

.homepage-cta-button {
  font-size: small;
  font-family: 'Major Mono Display';
  background-color: #74747b0c;
  color: whitesmoke;
  border-radius: 8px;
  margin-top: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-style: none;
}

.back-video-projects {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -2;
  filter: blur(10px); 
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);

}

.back-video-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 23, 33, 0.567);
  z-index: -1;
}

.homepage-footer {
  position: fixed;
  bottom: 10px;
  align-items: center;
  height: 20px;
  font-size: x-small;
  color: #f5f5f569;
}

.projects-header {
  padding-top: 160px;
  width: 90%;
  max-width: 90%;
  display: flex;
  flex: 2;
  flex-direction: column;
}

.highlight-text {
  color: whitesmoke;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  padding-left: 20px;
  font-size: medium;
  text-align: center;
}

.thumbnail-container {
  flex: 1;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.thumbnail {
  position: relative;
  width: 36%;
  height: auto;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.thumbnail-inner {
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.thumbnail img {
  width: 100%;
  height: auto;
}

.peek-image {
  position: absolute;
  top: 0;
  left: 100%;
  width: 93%;
  max-width: 93%;
  height: auto;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: -1;
}

.thumbnail-container:hover .peek-image {
  transform: translateX(-77%);
}

.client-logo-grid-container {
  width: 100%;
  background-color: #010004;
  display: flex;
  justify-content: center;
}

.client-logo-grid {
  padding-top: 24px;
  width: 85%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  /* grid-template-columns: repeat(3, 1fr); */
  grid-gap: 4px;
  justify-items: center;
  @media (max-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
    width: 85%;
  }
}

.client-logo-item {
  width: 100%;
  text-align: center;
}

.client-logo-item img {
  color: pink;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px;
  background-color: #010004;
}

.grid-item {
  flex: calc(33.33% - 20px);
  margin: 8px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  position: relative;
  @media (max-width: 600px) {
    margin: 0px;
  }

}

.grid-item img {
  max-width: 100%;
  height: auto;

  transition: all 0.44s ease;
}

.grid-item:hover img {
  filter: brightness(1.3);
}

.grid-item .grid-container-text {
  padding: 10px;
}

.grid-item .grid-container-text-title {
  font-size: larger;
  font-family: 'Syne', sans-serif;
  background: var(--gradient-text-2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grid-item .grid-container-text-subtitle {
  font-size: small;
  font-family: 'Syne', sans-serif;
  padding-top: 4px;
  padding-bottom: 12px;

  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 992px) {
  .grid-item {
      flex: calc(33.33% - 20px); /* Adjust margin and padding as needed */
  }
}

/* For medium-sized screens */
@media (max-width: 991px) {
  .grid-item {
      flex: calc(50% - 20px); /* Adjust margin and padding as needed */
  }
}

/* For mobile phones */
@media (max-width: 767px) {
  .grid-item {
      flex: 100%;
  }
}

@media (min-aspect-ratio: 16/9) {
  .back-video-projects {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .back-video-projects {
    width: auto;
    height: 100%;
  }
}